import React, { useState } from "react";
import { navBarItems } from "./NavigationBar";
import hamburgerIcon from "../resources/icons/hamburger-svgrepo-com.svg";

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative tablet:hidden">
      <button
        className=" text-txtColor1 p-3 fixed z-10 focus:outline-none md:hidden"
        onClick={toggleMenu}
      >
        <img src={hamburgerIcon} alt="" className="shadow-sm rounded-full"></img>
      </button>
      {isOpen && (
        <div className="fixed top-0 left-0 w-9/12 h-full bg-background2 text-txtColor1 p-3 z-10 transition-all duration-300">
          <div className="flex justify-between items-center mb-4">
            <button
              className="text-txtColor1 focus:outline-none"
              onClick={toggleMenu}
            >
              <img src={hamburgerIcon} alt=""></img>
            </button>
          </div>
          <div className="space-y-5">
            <div className="block py-2 my-2 px-4 text-lg"
            ></div>
            {navBarItems.map((item) => (
              <a
                key={item.label}
                href={item.lnk}
                className="block py-2 my-2 px-4 text-lg font-extraBold hover:underline hover:underline-offset-1"
                onClick={toggleMenu}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      )}
      <div
        className={`fixed top-0 left-0 w-screen h-screen ${isOpen ? "visible opacity-50" : "invisible opacity-0"
          } bg-black z-0 transition-all duration-300`}
        onClick={toggleMenu}
      ></div>
    </div>
  );
};

export default MobileNavbar;
