import React from "react";
import resources from "../resources";
const BinaryArtisanLogo= resources.images.companyLogo;

export const navBarItems = [
  { label: "Convert", lnk: "./home" },
  // { label: "Portfolio", lnk: "https://portfolio.binaryartisan.com" },
  { label: "Login", lnk: "./login" },
  // { label: "Store", lnk: "https://binaryartisanshop.com" },
  // { label: "Clients", lnk: "./clientportal" },
];

const NavItem = (itemInfo, i) => {
  const { label, lnk } = itemInfo;
  return (
    <div key={i} className="mx-2">
      <a
        href={lnk}
        className={`no-underline text-sm inline-block align-text-bottom tablet:text-md laptop:text-xl desktop:text-xxl desktop:mx-8 m-2 font-bold ${lnk.endsWith(window.location.pathname)?"text-accent2":"text-txtColor1"} hover:text-accent1 hover:no-underline transition duration-300`}
      >
        {label}
      </a>
    </div>
  );
};

//test branch build

// Todo: When clicked, go to home page.
const CompanyLogo = () => {
  return (
    <div>
      <img className="h-12 mx-5 inline-block laptop:h-20 desktop:h-28" src={BinaryArtisanLogo} alt="Logo"></img>
    </div>
  );
};

const NavBar = () => {
  return (
    <div className="mobile:hidden tablet:flex shadow-lg p-4 shadow-accent2 justify-center transition duration-700 bg-background1 hover:bg-black">
      {CompanyLogo()}
      {navBarItems.map((itemInfo, i) => NavItem(itemInfo, i))}
    </div>
  );
};

export default NavBar;
