import React from "react";
import resources from "../resources";

const { binaryLogo } = resources.images;
const goToWebsite = () => {
  window.location.href = "https://binaryartisan.com";
}

const PoweredByBA = () => {
  return (
    <div className="flex items-center space-x-4 mx-auto w-11/12 tablet:w-10/12 my-8 rounded-lg p-3 text-txtColor3">
      <div className="tablet:w-8/12 none tablet:inline-block" />
      <div className="w-full tablet:w-4/12 flex justify-between items-center">
        <p className="inline-block w-2/3 text-sm tablet:text-xs laptop:text-sm">
          This site is powered by{" "}
          <span className="font-bold text-md tablet:text-sm laptop:text-md" onClick={goToWebsite}>Binary Artisan</span>
        </p>
        <img
          src={binaryLogo}
          alt="binary artisan logo"
          className="w-4/12 inline-block cursor-pointer shadow-lg rounded-full"
          onClick={goToWebsite}
        />
      </div>
    </div>
  );
};

export default PoweredByBA;
