// import logo from './logo.svg';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  document.title = "ImgTransformer.com";

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={HomePage()}>
          </Route>
          <Route path="/home" element={HomePage()}>
          </Route>
          <Route path="/login" element={LoginPage()}>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
