import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import ConversionTool from "../components/ConversionTool";
import netlifyIdentity from "../identity";

// Nitpick Notes:
// 
// -Fonts
// 

const HomePage = ()=>{
  netlifyIdentity.init({});

  return (<div>
    {MobileNavbar()}{NavBar()}{MobileIcon()}
    <ConversionTool/>
    {Footer()}
  </div>)
};

export default HomePage