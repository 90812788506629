import React, { useState } from "react";
import axios from "axios";

const acceptedFileTypes = ['jpg', 'png', 'webp', 'gif', 'avif', 'tiff']

const ConversionTool = () => {
  const [uploadFile, setUploadFile] = useState(null);
  const [fileType, setFileType] = useState("jpeg");
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    const nameSplit = file.name.split(".");
    setFileName(nameSplit.slice(0, nameSplit.length - 1).join("."));

    reader.onload = (e) => {
      setUploadFile({ info: file, data: e.target.result });
    };

    reader.readAsDataURL(file);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const convertImage = () => {
    if (!uploadFile) {
      console.error("No file selected");
      return;
    }

    const requestBody = {
      inputFile: uploadFile.data.replace(/^data:image\/\w+;base64,/, ""),
      newFileType: fileType,
    };

    axios
      .post("/.netlify/functions/testSharp", requestBody)
      .then((response) => {
        console.log("Conversion successful");
        console.log("Converted buffer:", response.data.conversion);
        downloadFile(response.data.conversion);
      })
      .catch((error) => {
        console.error("Conversion error:", error);
      });
  };

  const downloadFile = (conversion) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/octet-stream;base64,${conversion}`;
    downloadLink.download = `${fileName}.${fileType}`;
    downloadLink.click();
  };

  return (
    <div
      id="ConversionTool"
      className="bg-background2 mx-auto w-11/12 tablet:w-10/12 my-8 rounded-lg p-3 shadow-lg text-txtColor3"
    >
      <div className="tablet:border tablet:border-txtColor3 rounded-sm tablet:m-5 min-h-36 tablet:shadow-lg">
        <div
          id="conversionFileSelection"
          className=" w-full tablet:w-7/12 block tablet:inline-block p-2 pl-3 align-top"
        >
          <input
            type="file"
            onChange={handleFileChange}
            // accept="image/jpeg,image/jpg,image/png,image/ico,image/svg+xml,image/webp"
            accept={`image/${acceptedFileTypes.join(',image/')}`}
            className="bg-night rounded-md"
          ></input>
          {uploadFile && (
            <img
              src={uploadFile.data}
              className="w-2/3 mx-auto my-8 rounded-sm shadow-md shadow-black bg-white"
              alt={uploadFile.info.name}
            />
          )}
        </div>
        <div
          id="conversionProperties"
          className="w-full tablet:w-5/12 tablet:shadow-lg block tablet:inline-block h-full p-2 pl-3 rounded-sm"
        >
          <div>
            <div>Choose new file Name</div>
            <input
              type="text"
              value={fileName}
              onChange={handleFileNameChange}
              className="w-9/12 mx-auto block my-4 text-sm bg-night rounded-md px-1"
            ></input>
            <p>Select New File Type:</p>
            <select
              value={fileType}
              onChange={handleFileTypeChange}
              className="w-9/12 mx-auto block my-4 text-sm bg-night rounded-md px-1"
            >
              {acceptedFileTypes.map((fType,i)=>(<option value={fType} key={i}>{fType.toUpperCase()}</option>))}
            </select>
          </div>

          <p className="font-bold text-center text-md tablet:text-sm laptop:text-md">Converted File Name:</p>
          <p className="text-center">
            {fileName}.{fileType}
          </p>
          <div></div>

          <button
            className="bg-accent2 hover:bg-txtColor3 hover:text-midnight duration-500 w-full text-lg tablet:text-md laptop:text-lg my-8 rounded-md"
            onClick={convertImage}
          >
            Convert Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversionTool;
