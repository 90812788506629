import React, { useEffect } from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import netlifyIdentity from "../identity";

export const handleLogin = () => {
  netlifyIdentity.open("login");
};

const handleSignUp = () => {
  netlifyIdentity.open("signup");
};

const IfLoggedOut = () => {
  useEffect(() => {
    netlifyIdentity.init({});
  }, []);

  useEffect(() => {
    const onLogin = () => {
      // User has logged in
      console.log("User logged in");
      window.location.reload(); // Reload the page
    };

    netlifyIdentity.on("login", onLogin);

    return () => {
      // Clean up the event listener
      netlifyIdentity.off("login", onLogin);
    };
  }, []);

  return (
    <div className="mt-12 block tablet:w-2/3 mx-auto">
      <p
        onClick={handleSignUp}
        className="tablet:inline-block  w-full tablet:w-1/2 py-8 text-lg font-bold text-center text-txtColor1 duration-300 bg-transparent hover:bg-accent1 hover:text-txtColor1 rounded-sm px-2"
      >
        Click Here to Sign Up
      </p>
      <p
        onClick={handleLogin}
        className=" tablet:inline-block  w-full tablet:w-1/2 py-8 text-lg font-bold text-center text-txtColor1 duration-300 bg-transparent hover:bg-accent1 hover:text-txtColor1 rounded-sm px-2"
      >
        Click Here to Login
      </p>
    </div>
  );
};

const IfLoggedIn = () => {
  useEffect(() => {
    netlifyIdentity.init({});
  }, []);

  useEffect(() => {
    const onLogout = () => {
      // User has logged out
      console.log("User logged out");
      window.location.reload(); // Reload the page
    };

    netlifyIdentity.on("logout", onLogout);

    return () => {
      // Clean up the event listener
      netlifyIdentity.off("logout", onLogout);
    };
  }, []);

  const currentUser = netlifyIdentity.currentUser();
  console.log(currentUser.email);

  return (
    <div className="mt-12 block tablet:w-2/3 mx-auto">
      <p className="text-md text-center my-5">
        Logged In as {currentUser.user_metadata.full_name}; {currentUser.email}
      </p>
      <p
        onClick={handleLogin}
        className=" tablet:block  w-full tablet:w-1/2 py-8 text-lg font-bold text-center text-txtColor1 duration-300 bg-transparent hover:bg-accent1 hover:text-txtColor1 rounded-sm px-2 mx-auto"
      >
        Click Here to Log Out
      </p>
    </div>
  );
};

const LoginPage = () => {
  netlifyIdentity.init({});
  const currentUser = netlifyIdentity.currentUser();

  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {currentUser !== null && IfLoggedIn()}
      {currentUser === null && IfLoggedOut()}
      {Footer()}
    </div>
  );
};

export default LoginPage;
